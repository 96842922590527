import { useEffect, useState } from "react";
import { CalendarIcon } from "../assets/calendarsvg";
import {
  capitalizeStatusText,
  formatMoney,
  handleGlobalDownloadInvoice,
  LSUserData,
  scrollBackToTop,
} from "../utils/functions";
import DatePicker from "react-datepicker";
import { InvoiceView } from "../components/invoice-view";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Orders } from "../assets/orders";
import moment from "moment";
import { relativeStatus } from "../utils/constants";
import { Loader } from "../components/loader";
import { Pagination } from "../components/pagination";
import axios from "axios";

export function SalesInvoices() {
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [invoiceStatus, setInvoiceStatus] = useState("all");
  const [selectedDates, setSelectedDates] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [pagination, setPagination] = useState({ totalCount: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { apiKey } = LSUserData() || {};

  useEffect(() => {
    handleFetchSalesInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceStatus, !!selectedDates[1], currentPage]);

  useEffect(() => {
    // reset pagination
    if (currentPage === 1) return;
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceStatus, !!selectedDates[1]]);

  const handleDownloadInvoice = async (invoice) => {
    const isSalesInvoice = true;
    await handleGlobalDownloadInvoice(
      invoice,
      setDownloading,
      apiKey,
      isSalesInvoice
    );
  };

  const handleFetchSalesInvoices = async () => {
    try {
      setLoadingScreen(true);
      const filters = {};
      if (invoiceStatus && invoiceStatus !== "all")
        filters.status = invoiceStatus;
      if (selectedDates && selectedDates.length === 2) {
        filters.startDate = selectedDates[0];
        if (selectedDates[1])
          filters.endDate = moment(selectedDates[1]).endOf("day");
      }
      const queryString = new URLSearchParams(filters).toString();
      const {
        data: {
          data: { invoices, pagination },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/sales/invoices?${queryString}&page=${currentPage}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setInvoices(invoices);
      setPagination({
        ...pagination,
        totalCount: pagination.totalPages * pagination.perPage,
        page: Number(pagination.page),
      });
      setLoadingScreen(false);
    } catch (error) {
      console.log("eree", error);
      setLoadingScreen(false);
    }
  };

  return (
    <div className='tdp_orders tdp_invoices'>
      <div className='header page-header'>
        <h2>Sales Invoices</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={`${invoiceStatus === "all" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("all");
                scrollBackToTop();
              }}
            >
              All invoices
            </button>
            <button
              className={`${invoiceStatus === "unpaid" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("unpaid");
                scrollBackToTop();
              }}
            >
              Unpaid
            </button>
            <button
              className={`${invoiceStatus === "paid" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("paid");
                scrollBackToTop();
              }}
            >
              Paid
            </button>
            <button
              className={`${invoiceStatus === "overdue" ? "active" : ""}`}
              onClick={() => {
                setInvoiceStatus("overdue");
                scrollBackToTop();
              }}
            >
              Overdue
            </button>
          </div>
          <div className='__filters'>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Filter Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) => {
                  setSelectedDates(dates);
                  scrollBackToTop();
                }}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
                popperPlacement='bottom-end'
              />
              <CalendarIcon />
            </div>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        <div className='orders-list'>
          {loadingScreen ? (
            <div className='screen-loader-wrapper'>
              <Skeleton count={10} />
            </div>
          ) : !loadingScreen && !invoices?.length ? (
            <div className='empty__list'>
              <Orders />
              <h3>
                {selectedDates.length && invoiceStatus === "all"
                  ? "No invoices matching your filter criteria"
                  : invoiceStatus !== "all"
                  ? `No ${invoiceStatus} invoices`
                  : "No Invoices Yet!"}
              </h3>
              <p onClick={() => navigate("/create-sales-order")}>
                Create a new sales order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : (
            <>
              <div className='entities'>
                <table className='table customer-portal-table'>
                  <thead>
                    <tr>
                      <th scope='col'>Amount</th>
                      <th scope='col' colSpan={1}>
                        Invoice Number
                      </th>
                      <th scope='col' colSpan={1}>
                        Bill To
                      </th>
                      <th scope='col' colSpan={1}>
                        Created On
                      </th>
                      <th scope='col' colSpan={1}>
                        Due by
                      </th>
                      <th scope='col' colSpan={4}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr
                        data-bs-toggle='offcanvas'
                        href='#offcanvasInvoiceView'
                        aria-controls='offcanvasInvoiceView'
                        onClick={() => setInvoiceDetails(invoice)}
                        key={invoice?._id}
                      >
                        <td className='amount'>
                          <p>
                            <span>
                              {invoice?.currency?.symbol}
                              {formatMoney(invoice.total || 0)}
                            </span>
                            <span>{invoice?.currency?.iso || "-"}</span>
                          </p>
                        </td>
                        <td>{invoice.invoiceNumber || "-"}</td>
                        <td>{invoice.outletBusinessName || "-"}</td>
                        <td>
                          <span>
                            {moment(invoice.createdAt).format("Do MMM")}
                          </span>
                        </td>
                        <td className='createdAt'>
                          <span>{moment(invoice.dueAt).format("Do MMM")}</span>
                          <span
                            className={`custom_status ${relativeStatus(
                              invoice.status
                            )}`}
                          >
                            {capitalizeStatusText(invoice.status)}
                          </span>
                        </td>
                        <td colSpan={4} className={"actions-td"}>
                          <span
                            role='button'
                            className='action-row-button'
                            onClick={() => {
                              if (downloading) return;
                              const isSalesInvoice = true;
                              handleGlobalDownloadInvoice(
                                invoice,
                                setDownloading,
                                apiKey,
                                isSalesInvoice
                              );
                            }}
                          >
                            {downloading &&
                            invoiceDetails?._id === invoice?._id ? (
                              <Loader />
                            ) : (
                              "Download Invoice"
                            )}
                          </span>
                          <span
                            role='button'
                            className='action-row-button'
                            onClick={() =>
                              navigate(`/sales-order/${invoice.orderIds[0]}`)
                            }
                          >
                            View Order
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </>
          )}
        </div>
      </div>
      <InvoiceView
        invoice={invoiceDetails}
        handleDownloadInvoice={(invoice) => handleDownloadInvoice(invoice)}
        downloading={downloading}
        setDownloading={setDownloading}
      />
    </div>
  );
}
