/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  capitalizeStatusText,
  errorHandler,
  formatMoney,
  LSUserData,
  processOrderCalculations,
  renderErrorToast,
} from "../utils/functions";
import { relativeStatus } from "../utils/constants";
import { PurchaseOrderDocument } from "./purchase-order-document.template";
import Skeleton from "react-loading-skeleton";
import { CalendarIcon } from "../assets/calendarsvg";
import { Loader } from "./loader";
import { DistributorDeliveryDrawer } from "./drawers/distributor-delivery.drawer";

export const showMarkAsShippedButton = (
  isDraft = false,
  isSalesOrder = false,
  order
) => {
  if (isDraft || !isSalesOrder || !order) return false;
  if (
    !["delivered", "dispatched"].includes(order?.shippingStatus) &&
    order?.paymentStatus !== "paid"
  )
    return true;
};

export const salesOrderStatus = (order) => {
  if (!order?.shippingStatus) return "";
  return ["accepted", "pending"].includes(order.shippingStatus.toLowerCase())
    ? "pending"
    : ["delivered", "shipped"].includes(order.shippingStatus.toLowerCase())
    ? "success"
    : ["dispatched", "processing"].includes(order.shippingStatus.toLowerCase())
    ? "processing"
    : ["skipped"].includes(order.shippingStatus.toLowerCase())
    ? "cancelled"
    : order.shippingStatus;
};

export function OrderView({ isSalesOrder }) {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const { apiKey, retailOutlets: [retailOutlet] = [] } = LSUserData() || {};
  const { branches = [], country = "" } = retailOutlet || {};
  const { orderId } = useParams();
  const location = useLocation();
  const isDraft = location?.state?.isDraft || false;
  const draftOrder = location?.state?.draftOrder || {};
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acceptingOrder, setAcceptingOrder] = useState(false);
  const [ukTrackingStatuses, setUkTrackingStatuses] = useState([]);
  const [trackingCode, setTrackingCode] = useState("");
  const [shipment, setShipment] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const isCountryUK = country === "GB";
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const allBranches = useMemo(() => {
    return [
      ...branches,
      {
        _id: retailOutlet?._id,
        outletBusinessName: retailOutlet?.outletBusinessName,
        streetName:
          retailOutlet?.formattedAddress || retailOutlet?.streetName || "",
        contactPhone: retailOutlet?.contactPhone || "",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  const branch = useMemo(() => {
    if (!selectedTransaction && !draftOrder) return;
    if (!allBranches.length) return;
    let transaction = selectedTransaction;
    if (isDraft) {
      transaction = draftOrder;
      if (loading) setLoading(false);
    }
    if (isSalesOrder) return { outletBusinessName: orderDetails?.customerName };
    if (!transaction) return;
    return allBranches.find((el) => el._id === transaction.retailOutletId);
  }, [allBranches, selectedTransaction, draftOrder, isDraft]);

  useEffect(() => {
    if (!isDraft && !isSalesOrder) getOrderDetails();
    if (isSalesOrder) getSalesOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSalesOrderDetails = async () => {
    try {
      const {
        data: {
          data: { order },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/sales/orders/${orderId}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      const detailBody = {
        reference: order?.orderNumber,
        status: order?.status,
        createdAt: order?.createdAt,
        outletBusinessName: order?.shippingAddress?.company,
        toAddress: order?.shippingAddress?.address1,
        items: order?.items,
      };
      setOrderDetails(order);
      setOrders([order]);
      setSelectedTransaction(detailBody);
      setLoading(false);
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching sales order details",
        message,
        "err-fetching-sales-order-details"
      );
    }
  };

  useEffect(() => {
    const deliveryDrawer = document.getElementById(
      "distributorDeliveryCanvasExample"
    );
    const handleCloseDeliveryDrawer = () => setSelectedOrder(null);
    // Bootstrap's event when modal is hidden
    deliveryDrawer?.addEventListener("hidden.bs.offcanvas", () =>
      handleCloseDeliveryDrawer()
    );
    return () => {
      deliveryDrawer.removeEventListener(
        "hidden.bs.offcanvas",
        handleCloseDeliveryDrawer()
      );
    };
  }, []);

  const getOrderDetails = async () => {
    if (!orderId) return;
    setLoading(true);
    try {
      const {
        data: {
          data: { orders, transaction, shipment },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v3/procurement/transactions/${orderId}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setOrders(orders);
      setSelectedTransaction(transaction);
      if (shipment.length) setShipment(shipment[0]);
      setLoading(false);
    } catch (error) {
      const { message } = errorHandler(error);
      setLoading(false);
      renderErrorToast(
        "Error fetching order details",
        message,
        "err-fetching-order-details"
      );
    }
  };

  const copyText = async (text) => {
    await navigator.clipboard.writeText(text);
    renderErrorToast(
      "Code Copied!",
      "Driver's code has been copied successfully!",
      "copy-code-success"
    );
  };

  const {
    items,
    toAddress,
    subtotal,
    total,
    currencySymbol,
    currency,
    discounts,
    shippingFees,
    taxes,
    shippingAdjustment,
  } = useMemo(() => {
    let items = [];
    let subtotal = 0,
      shippingAdjustment = 0,
      shippingFees = 0;
    let total = 0;
    let currencySymbol = "";
    let currency = "";
    let fromAddress = "-";
    let toAddress = "-";
    let discounts = 0;
    let taxes = 0;
    if (!isDraft && !orders.length && !isSalesOrder)
      return {
        items,
        subtotal,
        total,
        currencySymbol,
        toAddress,
        fromAddress,
        discounts,
        taxes,
        shippingFees,
        currency,
      };
    if (isDraft && !draftOrder) return;
    if (!isDraft && !isSalesOrder) {
      // let d = item.discount || 0;
      // if (!item.isPromo && item.status !== "deleted") {
      //   total = item.quantity * item.price * ((100 - d) / 100);
      // }
      // is an order
      const processedCalculations = processOrderCalculations(
        orders,
        selectedTransaction
      );
      items = processedCalculations.items;
      discounts = processedCalculations.discounts;
      total = processedCalculations.total;
      subtotal = processedCalculations.subtotal;
      shippingFees = processedCalculations.shippingFees;
      taxes = processedCalculations.taxes;
      currencySymbol = selectedTransaction?.currency?.symbol || "";
      currency = selectedTransaction.currency.iso || "";
      toAddress = branch?.streetName || "-";
      fromAddress =
        retailOutlet?.formattedAddress || retailOutlet?.streetName || "-";
    }
    if (isDraft) {
      items = draftOrder.items.map((el) => ({
        ...el,
        total: el.price * el.quantity,
      }));
      subtotal = draftOrder.orderTotal;
      total = draftOrder.orderTotal;
      currency = draftOrder.currency.iso || "";
      toAddress = branch?.streetName || "-";
      fromAddress = retailOutlet?.formattedAddress || "-";
      currencySymbol = draftOrder.currency.symbol;
    }
    if (isSalesOrder) {
      items = (orderDetails?.items ?? []).map((el) => ({
        ...el,
        total: el.price * el.quantity,
      }));
      subtotal = orderDetails?.promoItemTotal ?? 0;
      taxes = orderDetails?.promoTaxes ?? 0;
      total = orderDetails?.promoTotal ?? 0;
      discounts = orderDetails?.promoDiscounts ?? 0;
      currency = orderDetails?.currency?.iso ?? "";
      currencySymbol = orderDetails?.currency?.symbol ?? "";
      toAddress = orderDetails?.shippingAddress?.address1 ?? "";
      fromAddress = orderDetails?.stockLocationName ?? "";
      shippingFees =
        orderDetails?.shippingCosts || orderDetails?.processingCost || 0;
      shippingAdjustment = orderDetails?.shippingAdjustment || 0;
    }
    return {
      items,
      subtotal,
      total,
      currencySymbol,
      toAddress,
      fromAddress,
      discounts,
      shippingFees,
      taxes,
      shippingAdjustment,
      currency,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDraft,
    orders,
    draftOrder,
    selectedTransaction?.currency?.symbol,
    selectedTransaction?.retailOutletId,
    retailOutlet?.formattedAddress,
    branch,
    isSalesOrder,
    orderDetails,
  ]);

  const handleGetTrackingDetailsById = async () => {
    try {
      if (!shipment) return;
      const {
        data: {
          data: { orderStatus, trackingCode },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/order-shipment/tracking/${shipment._id}`,
        { headers: { "x-api-key": apiKey } }
      );
      setUkTrackingStatuses(orderStatus);
      setTrackingCode(trackingCode);
    } catch (error) {
      setLoading(false);
    }
  };

  const trackingStatus = useMemo(() => {
    if (!orders.length) return {};
    if (isCountryUK && !ukTrackingStatuses.length) {
      handleGetTrackingDetailsById();
      return {};
    }
    if (isCountryUK && ukTrackingStatuses.length) {
      const orderPlaced = ukTrackingStatuses.find(
        (el) => el.status === "order placed"
      );
      const orderScheduled = ukTrackingStatuses.find(
        (el) => el.status === "scheduled"
      );
      const orderOutForDelivery = ukTrackingStatuses.find(
        (el) => el.status === "out for delivery"
      );
      const orderDelivered = ukTrackingStatuses.find(
        (el) => el.status === "delivered"
      );
      return {
        isOrderPlaced: !!orderPlaced,
        orderPlacedDate: orderPlaced?.createdAt || null,
        isOrderConfirmed: !!orderScheduled,
        orderConfirmedDate: orderScheduled?.createdAt || null,
        isOrderOutForDelivery: !!orderOutForDelivery,
        orderOutForDeliveryDate: orderOutForDelivery?.createdAt || null,
        isOrderDelivered: !!orderDelivered,
        orderDeliveredDate: orderDelivered?.createdAt || null,
      };
    }
    const [order] = orders;
    const { shippingStatus, history } = order;
    const isOrderDelivered = [
      "delivered",
      "shipped",
      "out for delivery",
    ].includes(shippingStatus);
    const isOrderOutForDelivery =
      ["dispatched", "out for delivery"].includes(shippingStatus) ||
      isOrderDelivered;
    const isOrderConfirmed =
      ["scheduled", "processing", "skipped", "ready"].includes(
        shippingStatus
      ) || isOrderOutForDelivery;
    const isOrderPlaced =
      ["pending", "cancelled"].includes(shippingStatus) || isOrderConfirmed;
    const autoApprovalEventTime =
      history.find((el) => el?.event === "AUTO_APPROVAL")?.createdAt ?? null;
    const orderPlacedDate = isOrderPlaced ? orders[0].createdAt : null;
    const orderConfirmedDate = isOrderConfirmed
      ? orders[0].approvals?.[0]?.approvedAt || autoApprovalEventTime
      : null;
    const orderOutForDeliveryEventTime =
      history.find((el) => el?.event === "AUTO_ASSIGNED_TO_LOCATION")
        ?.createdAt ?? orders[0]?.shippedAt;
    const orderOutForDeliveryDate = isOrderOutForDelivery
      ? orders[0].dispatchedAt ?? orderOutForDeliveryEventTime
      : null;
    const orderDeliveredDate = isOrderDelivered ? orders[0].deliveredAt : null;
    return {
      orderConfirmedDate,
      orderDeliveredDate,
      orderPlacedDate,
      orderOutForDeliveryDate,
      isOrderConfirmed,
      isOrderPlaced,
      isOrderOutForDelivery,
      isOrderDelivered,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, isCountryUK, ukTrackingStatuses]);

  const handleAcceptOrder = async () => {
    setAcceptingOrder(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/global/order/accept/${orderId}`,
        {},
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      await getOrderDetails();
      setAcceptingOrder(false);
      return renderErrorToast(
        "Accepted Order Successfully!",
        "This order has been shipped successfully",
        "global-order-success"
      );
    } catch (error) {
      setAcceptingOrder(false);
      if (error?.response?.status === 404)
        return renderErrorToast(
          "Invalid Request",
          "Only global orders can be accepted. Please contact support",
          "global-order-err"
        );
      const {
        message = "There was an issue with accepting your order. Please contact support",
      } = errorHandler(error);
      return renderErrorToast(
        "Something went wrong!",
        message,
        "global-order-err-1"
      );
    }
  };

  return (
    <div className='tdp_order_view'>
      <div className='tdp_onboarding'>
        <div className='header'>
          <div className='cancel__'>
            <div onClick={() => navigate(-1)}>
              <i className='bi bi-x-lg'></i>
            </div>
            <h4>Order Summary</h4>
          </div>
          <div className='buttons_flex'>
            <button onClick={handlePrint}>Print</button>
            {showMarkAsShippedButton(isDraft, isSalesOrder, orders[0]) ? (
              <button
                data-bs-toggle='offcanvas'
                href='#distributorDeliveryCanvasExample'
                aria-controls='distributorDeliveryCanvasExample'
                onClick={() => setSelectedOrder(orders?.[0])}
              >
                Mark as delivered
              </button>
            ) : null}
            {isDraft ? (
              <button
                onClick={() => navigate("/create-order", { state: draftOrder })}
              >
                Edit
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className='body'>
        <div className='container-fluid'>
          {action === "accept" &&
          !shipment &&
          selectedTransaction &&
          selectedTransaction?.isGlobal ? (
            <div className='approval-container'>
              <p>This order need an approval. Click an action to proceed</p>
              <div className='actions'>
                {/* <button>Reject</button> */}
                <button onClick={handleAcceptOrder}>
                  {acceptingOrder ? <Loader /> : "Accept"}
                </button>
              </div>
            </div>
          ) : null}
          <div className={`_card-boxes ${isDraft ? "draft" : ""}`}>
            <div className='_left'>
              <div className='title'>
                <h2>
                  Order{" "}
                  {isDraft
                    ? draftOrder.draftOrderNumber
                    : orders?.[0]?.orderNumber}
                </h2>
                <div className='due_status_block'>
                  {isDraft ? (
                    <span className='draft'>Draft</span>
                  ) : isSalesOrder ? (
                    <span
                      className={`custom_status ${relativeStatus(
                        salesOrderStatus(orders?.[0])
                      )}`}
                    >
                      {capitalizeStatusText(orders?.[0]?.shippingStatus)}
                    </span>
                  ) : (
                    <span
                      className={`custom_status ${relativeStatus(
                        ["shipped", "accepted"].includes(
                          selectedTransaction?.status?.toLowerCase()
                        )
                          ? "success"
                          : ["open", "pending"].includes(
                              selectedTransaction?.status
                            )
                          ? "processing"
                          : selectedTransaction?.status
                      )}`}
                    >
                      {selectedTransaction?.status}
                    </span>
                  )}
                </div>
              </div>
              <div className='date'>
                <h4>Date</h4>
                <h6>
                  {selectedTransaction?.createdAt || draftOrder?.createdAt
                    ? moment(
                        selectedTransaction?.createdAt || draftOrder?.createdAt
                      ).format("Do MMM, YYYY")
                    : "-"}
                </h6>
              </div>
              {orders?.[0]?.isGlobal && ( // this shows for global orders alone
                <div className='approval-status location'>
                  <h4>Approval Status</h4>
                  <span
                    className={`custom_status ${relativeStatus(
                      orders?.[0]?.approvalStatus === "pending"
                        ? "processing"
                        : "success"
                    )}`}
                  >
                    {capitalizeStatusText(orders?.[0]?.approvalStatus)}
                  </span>
                </div>
              )}
              <div className='location'>
                <h4>Deliver to</h4>
                {loading ? (
                  <Skeleton />
                ) : (
                  <h6>
                    <p>{branch?.outletBusinessName || "-"}</p>
                    <p>{toAddress}</p>
                  </h6>
                )}
              </div>
              <div className='_custom-table'>
                <div className='_head'>
                  <p>Description</p>
                  {!(
                    isSalesOrder && orders?.[0]?.shippingStatus === "delivered"
                  ) && <p />}
                  <p>Ordered Qty</p>
                  {isSalesOrder &&
                  orders?.[0]?.shippingStatus === "delivered" ? (
                    <p>Delivered Qty</p>
                  ) : null}
                  <p>Unit Price</p>
                  <p>Discount</p>
                  <p>Tax</p>
                  <p>Amount</p>
                </div>
                <div className='_body'>
                  {loading
                    ? [1, 2].map((_, key) => (
                        <tr key={key}>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      ))
                    : items.map((el, key) => (
                        <tr key={key}>
                          <td className='item-1'>{el.name}</td>
                          {!(
                            isSalesOrder &&
                            orders?.[0]?.shippingStatus === "delivered"
                          ) && <td />}
                          <td className='item-2'>{el.quantity}</td>
                          {isSalesOrder &&
                          orders?.[0]?.shippingStatus === "delivered" ? (
                            <td className='item-2'>{el?.shippedQuantity}</td>
                          ) : null}
                          <td className='item-2'>
                            {`${currencySymbol}${formatMoney(el.price || 0)}`}
                          </td>
                          <td className='item-1'>
                            {`${currencySymbol}${formatMoney(
                              el.discount || 0
                            )}`}
                          </td>
                          <td className='item-1'>
                            {currencySymbol} {el.tax || 0}
                          </td>
                          <td className='item-1'>
                            {`${currencySymbol}${formatMoney(el.total || 0)}`}
                          </td>
                        </tr>
                      ))}
                </div>
                <div className='_totals'>
                  <div className='total-line'>
                    <p>Subtotal</p>
                    <p>
                      {currencySymbol} {formatMoney(subtotal || 0)}
                    </p>
                  </div>
                  <div className='total-line'>
                    <p>Discounts</p>
                    <p>
                      {currencySymbol} {formatMoney(discounts || 0)}
                    </p>
                  </div>
                  {taxes ? (
                    <div className='total-line'>
                      <p>Taxes</p>
                      <p>
                        {currencySymbol} {formatMoney(taxes)}
                      </p>
                    </div>
                  ) : null}
                  <div className='total-line'>
                    <p>Shipping Fees</p>
                    <p>
                      {currencySymbol} {formatMoney(shippingFees)}
                    </p>
                  </div>
                  {isSalesOrder &&
                  orders?.[0]?.shippingStatus === "delivered" ? (
                    <div className='total-line'>
                      <p>Shipping Adj.</p>
                      <p>
                        {currencySymbol} {formatMoney(shippingAdjustment)}
                      </p>
                    </div>
                  ) : null}
                  <div className='total-line'>
                    <p>Total</p>
                    <p>
                      {currencySymbol} {formatMoney(total)}
                    </p>
                  </div>
                  {orders?.[0]?.shippingStatus === "delivered" ||
                  orders?.[0]?.paymentStatus === "paid" ? null : (
                    <div className='total-line total'>
                      <p>
                        {draftOrder?.draftOrderNumber
                          ? "Estimated Amount"
                          : "Amount Due"}
                      </p>
                      <p>
                        {currencySymbol} {formatMoney(total)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isDraft ? (
              <div className='_right'>
                <h2>Order Tracking</h2>
                <div className='_tracking'>
                  <div className='tracking-steps'>
                    <div className='step'>
                      <div>
                        <h5>
                          <span className={`status-dot done`}></span>
                          Order Placed
                        </h5>
                        <div className='logistics'>
                          <div className='time'>
                            <CalendarIcon />
                            <span>
                              {moment(orders[0]?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='step'>
                      <div>
                        <h5>
                          <span
                            className={`status-dot ${
                              !trackingStatus.isOrderConfirmed
                                ? "pending"
                                : "done"
                            }`}
                          ></span>
                          Order Confirmed
                        </h5>
                        {trackingStatus?.isOrderConfirmed ? (
                          <div className='logistics'>
                            <div className='time'>
                              <CalendarIcon />
                              <span>
                                {moment(
                                  orders[0]?.approvals?.[0]?.approvedAt
                                ).format("DD/MM/YYYY hh:mm A")}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='step'>
                      <div>
                        <h5>
                          <span
                            className={`status-dot ${
                              !trackingStatus.isOrderOutForDelivery
                                ? "pending"
                                : "done"
                            }`}
                          ></span>
                          Out for Delivery
                        </h5>
                        <div className='info-notes'>
                          {trackingStatus.isOrderOutForDelivery ? (
                            <>
                              {orders[0]?.dispatchUser?.name ? (
                                <div className='driver_details'>
                                  <p>Driver name</p>
                                  <p>{orders[0].dispatchUser?.name}</p>
                                </div>
                              ) : null}

                              {trackingCode ? (
                                <div className='driver_details'>
                                  <p>Delivery Code</p>
                                  <p>
                                    {trackingCode}{" "}
                                    <button
                                      onClick={() => copyText(trackingCode)}
                                    >
                                      Copy code
                                    </button>
                                  </p>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                        {trackingStatus?.isOrderOutForDelivery ? (
                          <div className='logistics'>
                            <div className='time'>
                              <CalendarIcon />
                              <span>
                                {moment(
                                  orders[0]?.dispatchedAt ||
                                    orders[0]?.shippedAt
                                ).format("DD/MM/YYYY hh:mm A")}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='step'>
                      <div>
                        <h5>
                          <span
                            className={`status-dot ${
                              !trackingStatus?.isOrderDelivered
                                ? "pending"
                                : "done"
                            }`}
                          ></span>
                          Delivered
                        </h5>
                        {trackingStatus?.isOrderDelivered ? (
                          <div className='logistics'>
                            <div className='time'>
                              <CalendarIcon />
                              <span>
                                {moment(orders[0]?.deliveredAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentRef} className='tdp_create_orders'>
          <div className='body'>
            <div className='right__ detail'>
              <PurchaseOrderDocument
                downloading={!isDraft}
                orderReference={
                  draftOrder?.draftOrderNumber ||
                  selectedTransaction?.reference ||
                  ""
                }
                branch={branch}
                totalCalculation={{
                  subtotal,
                  discount: discounts,
                  total,
                  amountDue: total,
                  taxes,
                  shippingFees,
                }}
                currency={currencySymbol}
                reviewOrderData={null}
                showPromotionValue={null}
                currencyCode={currency}
                poOrders={{
                  items: items.map((el) => ({
                    ...el,
                    count: el.quantity,
                    currency: { symbol: currencySymbol },
                  })),
                }}
                order={draftOrder || selectedTransaction || null}
              />
            </div>
          </div>
        </div>
      </div>
      <DistributorDeliveryDrawer
        selectedOrder={selectedOrder}
        next={() => {
          getSalesOrderDetails();
          document.getElementById("close").click();
        }}
      />
    </div>
  );
}
