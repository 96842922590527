/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../components/loader";
import {
  errorHandler,
  formatMoney,
  isAParentOutlet,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import { toast } from "react-toastify";
import { CustomSelect } from "../components/custom-select";
import { useEffect, useMemo, useState } from "react";
import { EditSvg } from "../assets/edit";
import { LS_CART_PRODUCTS, SHOP } from "../utils/constants";
import { Tooltip } from "react-tooltip";
import { DeleteSvg } from "../assets/delete";
import axios from "axios";
import { ImageWithFallback } from "./console";
import { Counter } from "../components/counter";
import { ChevronRight } from "../assets/chevrons";
import { ShipmentIcon } from "../assets/shipment.icon";
import { PowerPlant } from "../assets/power-plants.icon";
import { SideProducts } from "../components/drawers/products.drawer";

const calculatePalletQuantity = (product) =>
  Number(
    ((product?.count || 0) / (product?.subUnit?.palletQuantity || 1)).toFixed(2)
  );

export function ExportCustomerCreateOrder() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { retailOutlets, apiKey } = LSUserData();
  const [retailOutlet] = retailOutlets;
  const { branches, contactPhone, isB2B, incoterms } = retailOutlet;
  const [showBranches, setShowBranches] = useState(false);
  const [branch, setBranch] = useState({ edit: true, value: {} });
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [showProductSearchInput, setShowProductSearchInput] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [exportVariants, setExportVariants] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [creatingDraft, setCreatingDraft] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [preparedOrders, setPreparedOrders] = useState(null);
  const [disableRecalculate, setDisableRecalculate] = useState(false);
  const [showFullContainerInformation, setShowFullContainerInformation] =
    useState(false);
  const [containerUtilization, setContainerUtilization] = useState(null);

  const locations = useMemo(() => {
    const allBranches = [...(branches || [])];
    allBranches.push({
      outletBusinessName: retailOutlet.outletBusinessName,
      streetName:
        retailOutlet?.formattedAddress || retailOutlet?.streetName || "-",
      _id: retailOutlet._id,
      account: retailOutlet.account,
    });
    return allBranches;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, retailOutlet]);

  useEffect(() => {
    // check to see if user is a child outlet
    if (!isAParentOutlet() || !isB2B) {
      setBranch({
        edit: false,
        outletBusinessName: retailOutlet.outletBusinessName,
        streetName:
          retailOutlet?.formattedAddress || retailOutlet.streetName || "",
        _id: retailOutlet._id,
        value: {
          name: retailOutlet.outletBusinessName,
          address: retailOutlet?.formattedAddress || retailOutlet?.streetName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchText) return;
    if (showProductsDropdown === true) setShowProductsDropdown(false);
    setFetchingProducts(true);
    const runSearchFunction = setTimeout(async () => {
      setShowProductsDropdown(true);
      setFetchingProducts(false);
    }, 1500);
    return () => clearTimeout(runSearchFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    scrollBackToTop();
    handleFetchExportVariants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.selectedProducts) {
      const products = state.selectedProducts.map((el) => ({
        ...el,
        palletCount: calculatePalletQuantity(el),
      }));
      setSelectedProducts([...products]);
    }
  }, [state]);

  const handleFetchExportVariants = async () => {
    try {
      const {
        data: {
          body: { exportVariants: variants },
        },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V3}/export-variants`,
        { headers: { "x-api-key": apiKey }, params: { limit: 10 } }
      );

      setExportVariants(
        (variants || []).map((el) => ({
          ...el,
          currency: el?.suppliers?.[0]?.currency,
          price: el?.suppliers?.[0]?.[incoterms?.toLowerCase()] || 0,
          supplier: el?.suppliers?.[0]?.companyId,
        }))
      );
      setFetchingProducts(false);
    } catch (error) {
      console.log("error fetching export variants", error);
    }
  };

  const searchedVariants = useMemo(() => {
    const allVariants = [...exportVariants];
    if (!searchText) return allVariants;
    return allVariants.filter((el) =>
      el.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, exportVariants]);

  const handleSelectDropdownProduct = (product) => {
    // check to see if product exist amongst the already selected products
    const selectedProductValueIndex = selectedProducts.findIndex(
      (el) => el._id === product._id
    );
    // if index > -1, the product exists : in that case, just make an edit for the existing
    // product selected
    if (selectedProductValueIndex > -1) {
      setShowProductsDropdown(false);
      return setSelectedProducts([
        {
          ...selectedProducts.find((el) => el._id === product._id),
          edit: true,
          key: selectedProductValueIndex,
        },
      ]);
    }
    // else, create newly
    setSelectedProducts([
      ...selectedProducts,
      {
        ...product,
        count: 1,
        palletCount: calculatePalletQuantity({ ...product, count: 1 }),
        quantity: 1,
      },
    ]);
    setShowProductSearchInput(false);
    setSearchText("");
    setShowProductsDropdown(false);
    setDisableRecalculate(false);
  };

  const handleChangeProcessingProductValue = (
    processingProduct,
    value,
    autoRefresh = false
  ) => {
    if (Number(value) > processingProduct?.extvariants?.available)
      return renderErrorToast(
        "Available quantity exceeded",
        `Only ${processingProduct?.extvariants?.available} available in stock`,
        "qty-exceeded-err-1"
      );
    const productInReviewIndex = selectedProducts.findIndex(
      (el) => el._id === processingProduct._id
    );
    selectedProducts[productInReviewIndex] = {
      ...selectedProducts[productInReviewIndex],
      count: Number(value),
      quantity: Number(value),
      palletCount: Number(
        calculatePalletQuantity({
          ...selectedProducts[productInReviewIndex],
          count: Number(value),
          quantity: Number(value),
        })
      ),
    };
    setSelectedProducts([...selectedProducts]);
    setDisableRecalculate(false);
  };

  const handleRemoveProduct = (productData, supplierId) => {
    const product = selectedProducts.find(
      (el) => el._id === productData?._id && el?.supplier === supplierId
    );
    if (!product) return;
    const remainingProducts = selectedProducts.filter(
      (el) => !(el._id === product?._id && el?.supplier === supplierId)
    );
    setSelectedProducts([...remainingProducts]);
    if (!remainingProducts.length) setShowProductSearchInput(true);
    setDisableRecalculate(false);
  };

  const handleEditPallet = (productData, supplierId, action) => {
    if (!action) return;
    // act upon a product pallet count
    const productPosition = selectedProducts.findIndex(
      (el) => el._id === productData?._id && el?.supplier === supplierId
    );
    let product = selectedProducts[productPosition];
    if (action === "add") {
      product = {
        ...product,
        palletCount: !Number.isInteger(product.palletCount)
          ? Math.ceil(product.palletCount)
          : Math.ceil(product.palletCount + 1),
      };
    }
    if (action === "remove") {
      if (product.palletCount <= 0 || product?.palletCount - 1 <= 0)
        return handleRemoveProduct(productData, supplierId);
      else
        product = {
          ...product,
          palletCount: !Number.isInteger(product.palletCount)
            ? Math.floor(product.palletCount)
            : Math.floor(product.palletCount - 1),
        };
    }
    product = {
      ...product,
      count: product.palletCount * product?.subUnit?.palletQuantity,
      quantity: product.palletCount * product?.subUnit?.palletQuantity,
    };
    selectedProducts[productPosition] = { ...product };
    setSelectedProducts([...selectedProducts]);
    setDisableRecalculate(false);
  };

  const handleEditProduct = (productData, supplierId, action) => {
    if (!action) return;
    // act upon a product count
    let product = selectedProducts.find(
      (el) => el._id === productData?._id && el?.supplier === supplierId
    );
    if (!product?.count)
      return renderErrorToast(
        "Product Quantity error",
        "You must add at least one (qty) of this item to cart",
        "product-quantity-err-1"
      );
    if (action === "add") {
      product = {
        ...product,
        count: (product.count || 0) + 1,
        quantity: (product.count || 0) + 1,
      };
    }
    if (action === "remove") {
      if (product.count <= 1)
        return handleRemoveProduct(productData, supplierId);
      else
        product = {
          ...product,
          count: product.count - 1,
          quantity: product.count - 1,
        };
    }
    product.palletCount = calculatePalletQuantity(product);
    const productIndex = selectedProducts.findIndex(
      (el) => el._id === productData?._id && el?.supplier === supplierId
    );
    selectedProducts[productIndex] = { ...product };
    setSelectedProducts([...selectedProducts]);
    setDisableRecalculate(false);
  };

  const handleSyncProducts = (products) => {
    const items = products.map((product) => ({
      ...product,
      count: product?.count || 1,
      quantity: product?.count || 1,
      palletCount: calculatePalletQuantity({
        ...product,
        count: product?.count || 1,
      }),
    }));
    setSelectedProducts([...items]);
    setDisableRecalculate(false);
  };

  const handleCreateOrder = async () => {
    try {
      setCreatingOrder(true);
      await axios.post(
        `${process.env.REACT_APP_TD_APP_URL}/api/v2/global/ext/orders`,
        preparedOrders
      );
      setCreatingOrder(false);
      renderErrorToast(
        "Successful",
        "We have successfully created your order",
        "success-order-msg"
      );
      localStorage.removeItem(LS_CART_PRODUCTS);
      return navigate("/orders");
    } catch (error) {
      setCreatingOrder(false);
      renderErrorToast(
        "Something went wrong!",
        "We ran into an issue creating your order. Please try again later or contact assistance",
        "success-order-msg"
      );
    }
  };

  const handleCreateCartOrder = async (preparedorders) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V2}/create-cart`,
        {
          ...preparedorders,
        },
        { headers: { "x-api-key": apiKey } }
      );
      await handleCreateDraftOrder(preparedorders);
    } catch (error) {
      const { customCode, message } = errorHandler(error);
      if (customCode === 1251) {
        const orderResponse = error?.response?.data?.orderResponse || null;
        if (!orderResponse)
          return renderErrorToast(
            "Error creating cart order",
            message,
            "cart-order-creation-err"
          );
        // changes happened to the order e.g price change or something else, so prepare order again
        const {
          data: { data },
        } = await axios.post(
          `${process.env.REACT_APP_TD_APP_URL}/api/v2/global/orders/prepare`,
          { ...orderResponse?.rawOrder }
        );
        handleCreateCartOrder(data);
      } else {
        setCreatingDraft(false);
        return renderErrorToast(
          "Error creating cart order",
          message,
          "cart-order-creation-err"
        );
      }
    }
  };

  const handlePrepareCreateDraftOrders = async () => {
    if (!branch?._id)
      return renderErrorToast(
        "Invalid Delivery Address",
        "Please select a valid delivery location",
        "delivery-location-err"
      );
    if (!selectedProducts?.length)
      return renderErrorToast(
        "Invalid Request",
        "Please select one or more products",
        "products-count-err"
      );
    setCreatingDraft(true);
    let preparedOrdersData = preparedOrders;
    if (!preparedOrdersData) {
      const prepareOrderBody = {
        retailOutletId: branch._id,
        items: selectedProducts.map((el) => ({ ...el, quantity: el.count })),
        extChannel: SHOP.B2B,
        contactPhone,
        issuedAt: new Date(),
        preview: true,
      };
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_TD_APP_URL}/api/v2/global/orders/prepare`,
        prepareOrderBody
      );
      preparedOrdersData = data;
    }
    try {
      await handleCreateCartOrder(preparedOrdersData);
    } catch (error) {
      setCreatingDraft(false);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Something went wrong while preparing draft order",
        message,
        "create-draft-order-err"
      );
    }
  };

  const handleCreateDraftOrder = async (preparedorder) => {
    // console.log({ preparedorder });
    // try {
    //   await axios.post(
    //     `${process.env.REACT_APP_AWS_API_URL_V2}/draft-order`,
    //     {
    //       retailOutlet: retailOutlet?._id,
    //       // retailOutletId:
    //       // preparedOrdersData.retailOutletId || retailOutlet?._id, // the branch outlet that the order is for
    //     },
    //     { headers: { "x-api-key": apiKey } }
    //   );
    //   renderErrorToast(
    //     "Successful",
    //     "Your order has been successfully created as a draft",
    //     "success-draft-order-msg"
    //   );
    //   navigate("/orders");
    //   setCreatingDraft(false);
    //   localStorage.removeItem(LS_CART_PRODUCTS);
    // } catch (error) {}
  };

  return (
    <div className='tdp_create_orders'>
      <div className='tdp_onboarding'>
        <div className='header'>
          <div className='cancel__'>
            <div
              onClick={() => {
                if (showFullContainerInformation)
                  return setShowFullContainerInformation(false);
                else navigate(-1) || navigate("/orders");
              }}
            >
              <i className='bi bi-x-lg'></i>
            </div>
            {!showFullContainerInformation ? (
              <h4>Create Order</h4>
            ) : (
              <h4>
                Cart <ChevronRight /> Container Information
              </h4>
            )}
          </div>
          <div className='buttons_flex'>
            <button
              style={{ display: "none" }}
              disabled={creatingDraft}
              onClick={handlePrepareCreateDraftOrders}
            >
              {creatingDraft ? <Loader /> : "Save as Draft"}
            </button>
            <button
              disabled={!preparedOrders || creatingOrder || !disableRecalculate}
              onClick={handleCreateOrder}
            >
              {creatingOrder ? <Loader /> : "Checkout"}
            </button>
          </div>
        </div>
        <div className='body'>
          {!showFullContainerInformation ? (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-8 _preview-form'>
                      {isAParentOutlet() ? (
                        <>
                          <div className='head_text'>
                            <h3>Delivery location</h3>
                          </div>
                          {branch.edit ? (
                            <CustomSelect
                              placeholder='Select Location'
                              open={showBranches}
                              setOpen={(e) => {
                                // e.stopPropagation();
                                setShowBranches(!showBranches);
                              }}
                              items={locations.map((location) => ({
                                ...location,
                                title: location.outletBusinessName,
                                subtext: location.streetName || "-",
                              }))}
                              action={(branch) => {
                                setBranch({
                                  ...branch,
                                  value: {
                                    name: branch.outletBusinessName,
                                    address: branch.streetName,
                                  },
                                  edit: false,
                                });
                                setShowBranches(false);
                              }}
                              selectedItem={{ ...branch }}
                            />
                          ) : (
                            <div className='_no-edit'>
                              <div className=''>
                                <h3>{branch.value.name}</h3>
                                <h5>{branch.value.address || "-"}</h5>
                              </div>
                              {isB2B ? (
                                <i
                                  onClick={() => {
                                    setBranch({ edit: true, value: {} });
                                  }}
                                >
                                  <EditSvg />
                                </i>
                              ) : null}
                            </div>
                          )}
                        </>
                      ) : null}
                      <div className='head_text'>
                        <h3>Add products</h3>
                        <div className='actions'>
                          <h6
                            data-bs-toggle='offcanvas'
                            href='#offcanvasExample'
                            role='button'
                            aria-controls='offcanvasExample'
                            onClick={() => setShowProductsDropdown(false)}
                          >
                            See all products
                          </h6>
                          {selectedProducts.length ? (
                            <button
                              onClick={() => {
                                setSelectedProducts([]);
                                setShowProductSearchInput(true);
                                setSearchText("");
                                setContainerUtilization(null);
                                localStorage.removeItem(LS_CART_PRODUCTS);
                              }}
                            >
                              Clear Cart
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <div className='custom-select custom-input'>
                        {selectedProducts.map((product, key) => {
                          return (
                            <ProcessingProduct
                              product={product}
                              key={key}
                              handleChangeProcessingProductValue={
                                handleChangeProcessingProductValue
                              }
                              removeProduct={() =>
                                handleRemoveProduct(product, product?.supplier)
                              }
                              handleEditProduct={(productId, action) =>
                                handleEditProduct(
                                  product,
                                  product?.supplier,
                                  action
                                )
                              }
                              handleEditPallet={(productId, action) =>
                                handleEditPallet(
                                  product,
                                  product?.supplier,
                                  action
                                )
                              }
                            />
                          );
                        })}
                        {showProductSearchInput ? (
                          <div className='product-input'>
                            <input
                              className='form-control'
                              placeholder='Find or add item'
                              onClick={(e) => e.stopPropagation()}
                              onChange={({ target: { value } }) =>
                                setSearchText(value)
                              }
                              value={searchText}
                            />
                            {fetchingProducts ? <Loader /> : null}
                          </div>
                        ) : (
                          <p
                            className={"_add-item"}
                            onClick={() => setShowProductSearchInput(true)}
                          >
                            <i className='bi bi-plus-lg'></i>
                            Add another item
                          </p>
                        )}
                        {showProductsDropdown ? (
                          <div className='_dropdown'>
                            {!searchedVariants.length ? (
                              <li>No products match your search</li>
                            ) : (
                              searchedVariants.map((el, key) => {
                                return (
                                  <li
                                    onClick={() =>
                                      handleSelectDropdownProduct(el)
                                    }
                                    key={key}
                                  >
                                    <div className=''>
                                      <p>{el.name}</p>
                                      {el?.extvariants ? (
                                        <p className='count'>
                                          {formatMoney(
                                            el?.extvariants?.available || 0
                                          )}{" "}
                                          items available
                                        </p>
                                      ) : null}
                                    </div>
                                    <p>
                                      {el.currency.symbol}
                                      {formatMoney(el.price)}
                                    </p>
                                  </li>
                                );
                              })
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className='head_text'>
                        <h3>Additional Details</h3>
                      </div>
                      <h5>
                        Enter an additional information you’d like the supplier
                        to be aware of
                      </h5>
                      <textarea
                        className='form-control'
                        placeholder='Note to supplier (optional)'
                        // onChange={({ target: { value } }) => setNote(value)}
                        // value={note}
                      />
                    </div>
                    <div className='col-2'></div>
                  </div>
                </div>
                <div className='col-6 right__'>
                  <ProcessingContainer
                    branch={branch}
                    selectedProducts={selectedProducts}
                    contactPhone={contactPhone}
                    preparedOrders={preparedOrders}
                    setPreparedOrders={setPreparedOrders}
                    setShowFullContainerInformation={
                      setShowFullContainerInformation
                    }
                    setContainerUtilization={setContainerUtilization}
                    containerUtilization={containerUtilization}
                    handleCreateOrder={handleCreateOrder}
                    creatingOrder={creatingOrder}
                    disableRecalculate={disableRecalculate}
                    setDisableRecalculate={setDisableRecalculate}
                  />
                </div>
              </div>
              <SideProducts
                syncProducts={(products) => handleSyncProducts(products)}
                variants={[...exportVariants]}
                fetchingProducts={fetchingProducts}
                selectedProducts={selectedProducts}
              />
            </div>
          ) : (
            <div className='container-fluid'>
              <ContainerInformation
                containerUtilization={containerUtilization}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ProcessingProduct({
  product,
  handleChangeProcessingProductValue,
  handleEditProduct,
  handleEditPallet,
  removeProduct = null,
  key,
}) {
  return (
    <div
      className='_processing-product'
      key={key}
      style={{ marginBottom: "12px" }}
    >
      <div className='export-details'>
        <div className='image_container'>
          <ImageWithFallback
            fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
            src={`${process.env.REACT_APP_PRODUCT_URL}/${product?.variantId}.png`}
            alt={product?.description}
            key={product?.variantId}
          />
          <p>{product?.name}</p>
          <span onClick={() => removeProduct(product)}>
            <DeleteSvg />
          </span>
        </div>
        <div className='input_containers'>
          <div className=''>
            <label>Quantity</label>
            <Counter
              handleAddProduct={(productId) =>
                handleEditProduct(productId, "add")
              }
              handleInputProductQuantity={(_, value) =>
                handleChangeProcessingProductValue(product, value)
              }
              handleRemoveProduct={(productId) =>
                handleEditProduct(productId, "remove")
              }
              cartProducts={[product]}
              product={product}
            />
          </div>
          <div className=''>
            <label>Pallet</label>
            <Counter
              disabled={!product?.subUnit?.palletQuantity}
              handleAddProduct={(productId) =>
                handleEditPallet(productId, "add")
              }
              handleInputProductQuantity={(_, value) =>
                handleChangeProcessingProductValue(product, value)
              }
              handleRemoveProduct={(productId) =>
                handleEditPallet(productId, "remove")
              }
              cartProducts={[
                { ...product, count: Number(product.palletCount) },
              ]}
              product={{ ...product, count: Number(product.palletCount) }}
            />
          </div>
          <div className=''>
            <label>Amount</label>
            <input
              disabled
              className='form-control amount'
              value={`${product?.currency?.iso} ${formatMoney(
                product?.price * product?.count
              )}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ContainerInformation({ containerUtilization }) {
  const [transportType, setTransportType] = useState("");
  const utilization = useMemo(() => {
    if (transportType === "truck") {
      return containerUtilization.reduce((acc, curr) => {
        let supplierProducts = curr.utilization.truck || [];
        supplierProducts = supplierProducts.map((el) => {
          return {
            productName: el.name,
            pallets: el.quantity,
            truckUsage: el?.usage || 0,
            limit: el?.limit || 0,
          };
        });
        const data = {
          supplierName: curr.name,
          supplierProducts,
          totalCases: curr.summary.truck.totalQuantity,
          totalTruckUsage: curr.summary.truck.truckUsage,
          totalTrucksRequired: curr.summary.truck.trucksRequired,
          lastTruckUtilization: curr.summary.truck.lastTruckUtilization,
        };
        return [...acc, data];
      }, []);
    } else
      return containerUtilization.reduce((acc, curr) => {
        let supplierProducts =
          curr.utilization.mid || curr.utilization.large || [];
        supplierProducts = supplierProducts.map((el) => {
          const mid = curr.utilization.mid;
          const large = curr.utilization.large;
          const teuContainer = mid.find((p) => p.variantId === el.variantId);
          const feuContainer = large.find((p) => p.variantId === el.variantId);
          return {
            productName: el.name,
            cases: el.quantity,
            feuLimit: feuContainer.limit,
            teuLimit: teuContainer.limit,
            feuUsage: feuContainer.usage,
            teuUsage: teuContainer.usage,
            teuContainersRequired: curr.summary.mid.containersRequired || 0,
            feuContainersRequired: curr.summary.large.containersRequired || 0,
            totalCases: curr.summary.large.totalQuantity,
          };
        });
        const data = {
          supplierName: curr.name,
          supplierProducts,
          totalCases: curr.summary.large.totalQuantity,
          feuTotalContainersRequired: curr.summary.large.containersRequired,
          teuTotalContainersRequired: curr.summary.mid.containersRequired,
          feuTotalLastUtilization: curr.summary.large.lastContainerUtilization,
          teuTotalLastUtilization: curr.summary.mid.lastContainerUtilization,
        };
        return [...acc, data];
      }, []);
  }, [containerUtilization, transportType]);

  return (
    <div className='row'>
      <div className='col-1'></div>
      <div className='col-10'>
        <div className='container-information'>
          <div className='_preview-form'>
            <div className='container-wrapper'>
              <div className='head_text__'>
                <h3>Container Information</h3>
                <h6 className='sub-text'>
                  Review container information and sourcing
                </h6>
              </div>
              <div className='transport-options'>
                <li
                  className={
                    !transportType || transportType === "direct" ? "active" : ""
                  }
                  onClick={() => setTransportType("direct")}
                >
                  Direct Sourcing
                </li>
                <li
                  className={transportType === "truck" ? "active" : ""}
                  onClick={() => setTransportType("truck")}
                >
                  Truck Load
                </li>
              </div>
              <div className='container-group'>
                <div class='accordion' id='accordionExample'>
                  {(utilization || [])?.map((container, key) => (
                    <div class='accordion-item' key={key}>
                      <h2 class='accordion-header'>
                        <button
                          class='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapse${key + 1}`}
                          aria-expanded='true'
                          aria-controls={`collapse${key + 1}`}
                        >
                          {container?.supplierName || ""}
                        </button>
                      </h2>
                      <div
                        id={`collapse${key + 1}`}
                        class='accordion-collapse collapse show'
                        data-bs-parent='#accordionExample'
                      >
                        <div class='accordion-body'>
                          {transportType === "truck" ? (
                            // truck transport type
                            <table className='table'>
                              <thead>
                                <tr>
                                  <th scope='col'>Description</th>
                                  <th scope='col'>Pallets</th>
                                  <th scope='col'>
                                    Loadability Limits{" "}
                                    <a
                                      data-tooltip-id='my-tooltip'
                                      data-tooltip-content='Number of cases that fill a truck.'
                                      data-tooltip-place='top'
                                    >
                                      <i class='bi bi-info-circle'></i>
                                    </a>
                                    <Tooltip id='my-tooltip' />
                                  </th>
                                  <th scope='col'>
                                    Truck usage{" "}
                                    <a
                                      data-tooltip-id='my-tooltip'
                                      data-tooltip-content='Amount of space used up in the trucks.'
                                      data-tooltip-place='top'
                                    >
                                      <i class='bi bi-info-circle'></i>
                                    </a>
                                    <Tooltip id='my-tooltip' />
                                  </th>
                                  <th scope='col'>
                                    Trucks required{" "}
                                    <a
                                      data-tooltip-id='my-tooltip'
                                      data-tooltip-content='Number of trucks needed.'
                                      data-tooltip-place='top'
                                    >
                                      <i class='bi bi-info-circle'></i>
                                    </a>
                                    <Tooltip id='my-tooltip' />
                                  </th>
                                  <th scope='col'>
                                    Utilization of last truck{" "}
                                    <a
                                      data-tooltip-id='my-tooltip'
                                      data-tooltip-content='% utilization of the last truck'
                                      data-tooltip-place='top'
                                    >
                                      <i class='bi bi-info-circle'></i>
                                    </a>
                                    <Tooltip id='my-tooltip' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {(container?.supplierProducts ?? []).map(
                                  (product, key) => (
                                    <tr key={key}>
                                      <th>{product?.productName}</th>
                                      <td>
                                        <div>
                                          {formatMoney(product?.pallets || 0)}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {product?.limit
                                            ? formatMoney(product.limit)
                                            : 0}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {product?.truckUsage
                                            ? formatMoney(product.truckUsage)
                                            : 0}
                                        </div>
                                      </td>
                                      <td>
                                        <div>-</div>
                                      </td>
                                      <td>
                                        <div>-</div>
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr className='total'>
                                  <th>Total</th>
                                  <td>{container?.totalCases}</td>
                                  <td>-</td>
                                  <td>{container?.totalTruckUsage}</td>
                                  <td>{container?.totalTrucksRequired}</td>
                                  <td>{container?.lastTruckUtilization}%</td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            // direct option
                            <table className='table'>
                              <thead>
                                <tr>
                                  <th scope='col'>Description</th>
                                  <th scope='col'>Quantity(Cases)</th>
                                  <th scope='col'>
                                    <p>
                                      Loadability Limits{" "}
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content='Number of cases that fill a container.'
                                        data-tooltip-place='top'
                                      >
                                        <i class='bi bi-info-circle'></i>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </p>
                                    <tr>
                                      <td>20ft</td>
                                      <td>40ft</td>
                                    </tr>
                                  </th>
                                  <th scope='col'>
                                    <p>
                                      Container usage{" "}
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content='Amount of space used up in the container.'
                                        data-tooltip-place='top'
                                      >
                                        <i class='bi bi-info-circle'></i>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </p>
                                    <tr>
                                      <td>20ft</td>
                                      <td>40ft</td>
                                    </tr>
                                  </th>
                                  <th scope='col'>
                                    <p>
                                      Container required
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content='Number of containers need.'
                                        data-tooltip-place='top'
                                      >
                                        <i class='bi bi-info-circle'></i>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </p>
                                    <tr>
                                      <td>20ft</td>
                                      <td>40ft</td>
                                    </tr>
                                  </th>
                                  <th scope='col'>
                                    <p>
                                      Utilization of last container
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content='% utilization of the last container'
                                        data-tooltip-place='top'
                                      >
                                        <i class='bi bi-info-circle'></i>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </p>
                                    <tr>
                                      <td>20ft</td>
                                      <td>40ft</td>
                                    </tr>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {(container?.supplierProducts ?? []).map(
                                  (product, key) => (
                                    <tr key={key}>
                                      <th>{product?.productName}</th>
                                      <td>
                                        <div>{formatMoney(product?.cases)}</div>
                                      </td>
                                      <td>
                                        <div>
                                          <td>{product?.teuLimit || 0}</td>
                                          <td>{product?.feuLimit || 0}</td>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <td>{product?.teuUsage || 0}</td>
                                          <td>{product?.feuUsage || 0}</td>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <td>-</td>
                                          <td>-</td>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <td>-</td>
                                          <td>-</td>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr className='total'>
                                  <th>Total</th>
                                  <td>{container?.totalCases}</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>
                                    <div>
                                      <td>
                                        {container?.teuTotalContainersRequired}
                                      </td>
                                      <td>
                                        {container?.feuTotalContainersRequired}
                                      </td>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <td>
                                        {container?.teuTotalLastUtilization}%
                                      </td>
                                      <td>
                                        {container?.feuTotalLastUtilization}%
                                      </td>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-1'></div>
    </div>
  );
}

function ProcessingContainer({
  branch,
  selectedProducts = {},
  contactPhone,
  preparedOrders,
  setPreparedOrders,
  containerUtilization,
  setContainerUtilization,
  setShowFullContainerInformation,
  handleCreateOrder,
  creatingOrder,
  disableRecalculate,
  setDisableRecalculate,
}) {
  const [page, setPage] = useState(containerUtilization?.length ? 2 : 1);
  const [loading, setLoading] = useState(false);
  const [transportType, setTransportType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (containerUtilization?.length) setPage(2);
    if (!containerUtilization || !containerUtilization.length) setPage(1);
  }, [containerUtilization]);

  const handleCalculateOrderArithmetic = () => handlePrepareOrder();

  const handlePrepareOrder = async () => {
    if (!branch?._id) {
      return renderErrorToast(
        "Invalid Delivery Address",
        "Please select a valid delivery location",
        "delivery-location-err"
      );
    }
    if (!selectedProducts?.length)
      return renderErrorToast(
        "Invalid Request",
        "Please select one or more products",
        "products-count-err"
      );
    setLoading(true);
    const items = selectedProducts.map((product) => {
      const selectedSupplier = product?.suppliers?.find(
        (supplier) => supplier.companyId === product?.supplier
      );
      return {
        _id: product?._id,
        variantId: product?.variantId,
        code: product?.code || "",
        price: product?.price || 0,
        supplier: selectedSupplier?.companyId || "",
        locationId: selectedSupplier?.locationId || "",
        quantity: product?.count,
      };
    });
    try {
      const prepareOrderBody = {
        retailOutletId: branch._id,
        items,
        extChannel: SHOP.B2B,
        contactPhone,
        issuedAt: new Date(),
        preview: true,
      };
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_TD_APP_URL}/api/v2/global/orders/prepare`,
        prepareOrderBody
      );
      if (data.containerUtilization) {
        setPreparedOrders(data);
        setContainerUtilization(data.containerUtilization);
        setPage(2);
      }
      setLoading(false);
      setDisableRecalculate(true);
    } catch (error) {
      const {
        message = "Something went wrong with preparing your order. Please try again later",
      } = errorHandler(error);
      setLoading(false);
      if (message.toLowerCase().includes("overdue loans"))
        return renderErrorToast(
          "Something went wrong with preparing your order",
          message,
          "error-prepare-1",
          <a
            className='action-link'
            onClick={() => {
              navigate("/invoices", { state: { filter: "overdue" } });
              toast.dismiss({ id: "error-prepare-1" });
            }}
          >
            View Loans
          </a>
        );
      else
        return renderErrorToast(
          "Something went wrong with preparing your order",
          message,
          "error-prepare-1"
        );
    }
  };

  const computedValues = useMemo(() => {
    let total = 0;
    let currency = {};
    if (!preparedOrders?.orders) return { total, currency };
    total = preparedOrders?.orders.reduce(
      (acc, curr) => acc + (curr?.itemTotal || 0),
      0
    );
    currency = preparedOrders?.orders[0].currency;
    return { total, currency };
  }, [preparedOrders]);

  return page === 1 ? (
    <div className='items_processing_container page_1'>
      <div className='icons-wrapper'>
        <ShipmentIcon />
        <PowerPlant />
      </div>
      <h3>Sourcing and Container information</h3>
      <h6>
        Click the “Calculate” button to calculate sourcing and container
        information
      </h6>
      <button disabled={loading} onClick={handleCalculateOrderArithmetic}>
        {loading ? <Loader /> : "Calculate"}
      </button>
    </div>
  ) : page === 2 ? (
    <div className='items_processing_container page_2'>
      <div className='order_total_container'>
        <div>
          <h4>Order Total</h4>
          <h4>
            {computedValues?.currency?.iso} {formatMoney(computedValues?.total)}
          </h4>
        </div>
        <button
          disabled={creatingOrder || !disableRecalculate}
          onClick={handleCreateOrder}
        >
          {creatingOrder ? <Loader /> : "Checkout"}
        </button>
      </div>
      <div className='order_container_information'>
        <h3>Container Information</h3>
        <div className='transport-options'>
          <li
            className={
              !transportType || transportType === "direct" ? "active" : ""
            }
            onClick={() => setTransportType("direct")}
          >
            Direct Sourcing
          </li>
          <li
            className={transportType === "truck" ? "active" : ""}
            onClick={() => setTransportType("truck")}
          >
            Truck Load
          </li>
        </div>
        <div class='accordion' id='accordionExample'>
          {(containerUtilization ?? []).map((el, key) => {
            return (
              <div class='accordion-item' key={key}>
                <h2 class='accordion-header'>
                  <button
                    class='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${key + 1}`}
                    aria-expanded='true'
                    aria-controls={`collapse${key + 1}`}
                  >
                    {el?.name}
                  </button>
                </h2>
                <div
                  id={`collapse${key + 1}`}
                  class='accordion-collapse collapse show'
                  data-bs-parent='#accordionExample'
                >
                  <div class='accordion-body'>
                    {!transportType || transportType === "direct" ? (
                      <table className='table'>
                        <thead>
                          <tr>
                            <th scope='col'>Container required</th>
                            <th scope='col'>Utilization of last container</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <p>20ft</p>
                                <p>40ft</p>
                              </div>
                              <div id='values'>
                                <p>
                                  {el?.summary?.mid?.containersRequired || "-"}
                                </p>
                                <p>
                                  {el?.summary?.large?.containersRequired ||
                                    "-"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div>
                                <p>20ft</p>
                                <p>40ft</p>
                              </div>
                              <div id='values' className='percent'>
                                <p
                                  className={
                                    el?.summary?.mid?.containerUsage >
                                    el?.summary?.large?.containerUsage
                                      ? "green"
                                      : "red"
                                  }
                                >
                                  {(
                                    el?.summary?.mid?.containerUsage || 0
                                  ).toFixed(2) || "-"}
                                  %
                                </p>
                                <p
                                  className={
                                    el?.summary?.large?.containerUsage >
                                    el?.summary?.mid?.containerUsage
                                      ? "green"
                                      : "red"
                                  }
                                >
                                  {(
                                    el?.summary?.large?.containerUsage || 0
                                  ).toFixed(2) || "-"}
                                  %
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      // transport type is TRUCK
                      <table className='table'>
                        <thead>
                          <tr>
                            <th scope='col'>Trucks required</th>
                            <th scope='col'>Utilization of last truck</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div id='values' style={{ borderBottom: 0 }}>
                                <p>
                                  {el?.summary?.truck?.trucksRequired || "-"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                id='values'
                                className='percent'
                                style={{ borderBottom: 0 }}
                              >
                                <p className='green'>
                                  {(
                                    el?.summary?.truck?.truckUsage || 0
                                  ).toFixed(2) || "-"}
                                  %
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='action'>
          <button
            disabled={loading}
            onClick={() => setShowFullContainerInformation(true)}
          >
            View detailed information
          </button>
          <button
            disabled={loading || disableRecalculate}
            onClick={handlePrepareOrder}
          >
            {loading ? <Loader /> : "Recalculate"}
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
