/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {
  capitalizeStatusText,
  errorHandler,
  formatMoney,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../assets/calendarsvg";
import { Orders as OrdersSvg } from "../assets/orders";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { relativeStatus } from "../utils/constants";
import moment from "moment";
import { Pagination } from "../components/pagination";
import { InfoSvg2 } from "../assets/info";
import { DistributorDeliveryDrawer } from "../components/drawers/distributor-delivery.drawer";
import { CheckSvg } from "../assets/check";
import {
  salesOrderStatus,
  showMarkAsShippedButton,
} from "../components/order-view";

export function SalesOrders() {
  const { apiKey } = LSUserData() || {};
  const [orderStatus, setOrderStatus] = useState("all");
  const [selectedDates, setSelectedDates] = useState([]);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({ totalCount: 0, perPage: 10 });
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  // const [printing, setPrinting] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const hasFilter = true;

  const toggleDropdown = (event, key) => {
    event.stopPropagation();
    if (dropdownIndex === key) return setDropdownIndex(null);
    setDropdownIndex(key);
  };

  useEffect(() => {
    const deliveryDrawer = document.getElementById(
      "distributorDeliveryCanvasExample"
    );
    const handleCloseDeliveryDrawer = () => setSelectedOrder(null);
    // Bootstrap's event when modal is hidden
    deliveryDrawer?.addEventListener("hidden.bs.offcanvas", () =>
      handleCloseDeliveryDrawer()
    );
    return () => {
      deliveryDrawer.removeEventListener(
        "hidden.bs.offcanvas",
        handleCloseDeliveryDrawer()
      );
    };
  }, []);

  useEffect(() => {
    if (!searchText && !searchText?.length) return;
    const runFunc = setTimeout(async () => {
      handleFetchSalesOrders();
    }, 2000);
    return () => clearTimeout(runFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    handleFetchSalesOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus, !!selectedDates[1], currentPage]);

  useEffect(() => {
    // reset pagination
    if (currentPage === 1) return;
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!selectedDates[1], orderStatus]);

  const handleFetchSalesOrders = async () => {
    try {
      setLoadingScreen(true);
      const filters = {};
      if (orderStatus && orderStatus !== "all") {
        if (orderStatus === "pending") filters.status = "open";
        else if (orderStatus === "completed") filters.status = "shipped";
        else filters.status = orderStatus;
      }
      if (searchText && searchText.length) filters.searchText = searchText;
      if (selectedDates && selectedDates.length === 2) {
        filters.startDate = selectedDates[0];
        if (selectedDates[1])
          filters.endDate = moment(selectedDates[1]).endOf("day");
      }
      const queryString = new URLSearchParams(filters).toString();
      const res = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/sales/orders?${queryString}&page=${currentPage}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      if (searchText && searchText.length) {
        setOrders(res.data);
        setPagination({ ...pagination, totalCount: res.data.length, page: 1 });
      } else {
        const {
          data: {
            data: { orders, pagination },
          },
        } = res;
        setOrders(orders);
        setPagination({
          ...pagination,
          totalCount: pagination.totalPages * pagination.perPage,
          page: Number(pagination.page),
        });
      }
      setLoadingScreen(false);
    } catch (error) {
      const {
        message = "There was an issue with fetching sales orders. Please try again later.",
      } = errorHandler(error);
      setLoadingScreen(false);
      renderErrorToast("Something went wrong!", message, "error-sales-order");
    }
  };

  return (
    <div className='tdp_orders tdp_invoices'>
      <div className='header page-header'>
        <h2>Sales Orders</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={orderStatus === "all" ? "active" : ""}
              onClick={() => {
                setOrderStatus("all");
                scrollBackToTop();
              }}
            >
              All orders
            </button>
            <button
              className={orderStatus === "pending" ? "active" : ""}
              onClick={() => {
                setOrderStatus("pending");
                scrollBackToTop();
              }}
            >
              Pending
            </button>
            <button
              className={orderStatus === "completed" ? "active" : ""}
              onClick={() => {
                setOrderStatus("completed");
                scrollBackToTop();
              }}
            >
              Completed
            </button>
          </div>
          <div className='__filters'>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Filter Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) => {
                  setSelectedDates(dates);
                  scrollBackToTop();
                }}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
              />
              <CalendarIcon />
            </div>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        {
          <div
            className={`__input-search ${
              !loadingScreen && !orders.length ? "empty" : ""
            }`}
          >
            <i className='bi bi-search'></i>
            <input
              className='form-control order-search'
              placeholder='Type to search by destination'
              onChange={({ target: { value } }) => {
                setSearchText(value);
                scrollBackToTop();
              }}
            />
          </div>
        }
        <div className='orders-list'>
          {loadingScreen ? (
            <div className='screen-loader-wrapper'>
              <Skeleton count={10} />
            </div>
          ) : !loadingScreen && !orders.length ? (
            <div className='empty__list'>
              <OrdersSvg />
              <h3>
                {hasFilter && orderStatus !== "all"
                  ? `No ${orderStatus} orders available`
                  : hasFilter
                  ? "No orders matching your filter criteria"
                  : "No Orders Yet!"}
              </h3>
              <p onClick={() => navigate("/create-sales-order")}>
                Create a new sales order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : !loadingScreen ? (
            <div className='entities'>
              <table className='table customer-portal-table sales-order-table'>
                <thead>
                  <tr>
                    <th scope='col' className='pd-left-30' colSpan={1}>
                      Amount
                    </th>
                    <th scope='col' colSpan={1}>
                      Summary
                    </th>
                    <th scope='col'>Delivering to</th>
                    <th scope='col'>Created On</th>
                    <th scope='col' colSpan={2}></th>
                    <th scope='col' colSpan={1} />
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, key) => (
                    <tr
                      key={key}
                      onClick={() => navigate(`/sales-order/${order._id}`)}
                    >
                      <td className='amount' colSpan={1}>
                        <p>
                          <span>
                            {order?.currency?.symbol}
                            {formatMoney(order?.total || 0)}
                          </span>
                          <span>{order?.currency?.iso || "-"}</span>
                        </p>
                      </td>
                      <td colSpan={1}>
                        <span className='summary'>
                          {order?.summary ||
                            order?.items?.map(
                              (el, key) =>
                                `${el.name} ${order.items[key + 1] ? ", " : ""}`
                            )}
                        </span>
                      </td>
                      <td>
                        <span>{order?.customerName || "-"}</span>
                      </td>
                      <td className='createdAt'>
                        <span>{moment(order.createdAt).format("Do MMM")}</span>
                        {orderStatus === "all" ? (
                          <span
                            className={`custom_status ${relativeStatus(
                              salesOrderStatus(order)
                            )}`}
                          >
                            {capitalizeStatusText(order.shippingStatus)}
                          </span>
                        ) : null}
                      </td>
                      <td colSpan={2} />
                      <td onClick={(e) => toggleDropdown(e, order?._id)}>
                        <i
                          className='bi bi-three-dots'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        />
                        <ul
                          class={`dropdown-menu ${
                            dropdownIndex === order?._id ? "show" : ""
                          }`}
                        >
                          <li
                            onClick={() =>
                              navigate(`/sales-order/${order._id}`)
                            }
                          >
                            <InfoSvg2 />
                            <a class='dropdown-item' href=''>
                              View order details
                            </a>
                          </li>
                          {showMarkAsShippedButton(false, true, order) ? ( // isDraft = false, isSalesOrder = true, order
                            <li onClick={() => setSelectedOrder(order)}>
                              <CheckSvg />
                              <a
                                className='dropdown-item'
                                data-bs-toggle='offcanvas'
                                href='#distributorDeliveryCanvasExample'
                                role='button'
                                aria-controls='distributorDeliveryCanvasExample'
                              >
                                Mark as delivered
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </div>
          ) : null}
        </div>
      </div>
      <DistributorDeliveryDrawer
        next={() => {
          handleFetchSalesOrders();
          document.getElementById("close").click();
        }}
        selectedOrder={selectedOrder}
      />
    </div>
  );
}
